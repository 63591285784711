import { eksmoBP } from 'Lib/bp/bp';
import { isTouch } from 'Lib/istouch/istouch';
import { getW } from 'Lib/w/w';
import { onScreen } from 'Lib/on-screen/on-screen';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import { eventSender } from 'Lib/event-sender/event-sender';

const $body = $('body');
const $collectionItem = $('.collection');
const $pagenavAjax = $('.pagenav-ajax');
const sCollectionPersonal = '.collection__personal-btn-box';
const cCollectionPersonalActive = 'collection__personal-btn-box_active';
const sImgForLoad = '.collection__series-image, .collection__pic';

function loadImg() {
    const $collectionSeries = $(sImgForLoad);

    onScreen($collectionSeries, ($el) => {
        if (!$el.hasClass('collection__series-image_empty')) {
            $el.addClass('collection__image-load');
        }

        loadSrcOrBg($el);
    });
}

function sendEventGA4($el, event) {
    eventSender.customDL({
        event: 'eksmo',
        eventAction: event,
        eventCategory: $('title').text().trim(),
        eventLabel: `Подборки - ${$el.find('.collection__name').text().trim()}`,
    });
}

loadImg();

$pagenavAjax.each(function () {
    $(this).on('eksmoPagenavAjax', loadImg);
});

onScreen($collectionItem, ($el) => {
    sendEventGA4($el, 'view');
}, 0, 0, 0.8);

$collectionItem.on('click', function () {
    sendEventGA4($(this), 'click');
});

$body.on('click', sCollectionPersonal, function () {
    const $this = $(this);

    if (getW() >= eksmoBP.smMin && !isTouch()) {
        $(sCollectionPersonal).not(this).removeClass(cCollectionPersonalActive);
        $this.toggleClass(cCollectionPersonalActive);
    }
});

$(document).on('mouseup', (e) => {
    const $collectionPersonal = $(sCollectionPersonal);

    if (!$collectionPersonal.is(e.target) && $collectionPersonal.has(e.target).length === 0) {
        $collectionPersonal.removeClass(cCollectionPersonalActive);
    }
});

// Нажатие на кнопку "Поделиться"
$body.on('click', '.collection__personal-menu-item_share', function () {
    const $collection = $(this).closest('.collection');
    const id = $collection.attr('data-id');
    const link = $collection.find('.collection__link').attr('href');

    if (typeof id === 'string' && typeof link === 'string') {
        $collection.trigger('eksmoCollectionShare', [id, link, $collection.attr('data-href-mail'), $collection.attr('data-vk-href'), $collection.attr('data-tg-href'), $collection.attr('data-ok-href'), $collection.attr('data-tw-href')]);
    }
});

// Нажатие на кнопку "Редактировать"
$body.on('click', '.collection__personal-menu-item_edit', function () {
    const $collection = $(this).closest('.collection');
    const id = $collection.attr('data-id');
    const name = $collection.find('.collection__name').text();

    if (typeof id === 'string' && typeof name === 'string') {
        $collection.trigger('eksmoCollectionEdit', [id, name, $collection.attr('data-description')]);
    }
});

// Нажатие на кнопку "Удалить"
$body.on('click', '.collection__personal-menu-item_delete', function () {
    const $collection = $(this).closest('.collection');
    const id = $collection.attr('data-id');
    const name = $collection.find('.collection__name').text();

    if (typeof id === 'string' && typeof name === 'string') {
        $collection.trigger('eksmoCollectionDelete', [id, name]);
    }
});